import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "section-border"
};
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");
  return _openBlock(), _createElementBlock("section", _hoisted_1, [_createVNode(_component_el_breadcrumb, {
    separator: "/"
  }, {
    default: _withCtx(() => [_ctx.$route.meta.breadcrumb ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$route.meta.breadcrumb, (item, index) => {
      return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
        key: index,
        to: item.link
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item.title), 1)]),
        _: 2
      }, 1032, ["to"]);
    }), 128))])) : _createCommentVNode("", true)]),
    _: 1
  })]);
}