import { Van, Key, User, Place, SetUp, Setting, Tickets, House, Monitor, Calendar, DataLine, Handbag, CoffeeCup, DataAnalysis, TakeawayBox, CollectionTag, OfficeBuilding } from '@element-plus/icons-vue';
import BaseMenu from './menu.vue';
import NoPrivilege from './no-privilege.vue';
export default {
  components: {
    Van,
    User,
    Key,
    Place,
    SetUp,
    House,
    Setting,
    Tickets,
    Monitor,
    Calendar,
    DataLine,
    Handbag,
    CoffeeCup,
    BaseMenu,
    NoPrivilege,
    DataAnalysis,
    TakeawayBox,
    OfficeBuilding,
    CollectionTag
  },
  data() {
    return {
      collapse: false,
      menuList: [],
      defaultOpeneds: []
    };
  },
  watch: {
    '$store.state.adminInfo': {
      handler(val) {
        this.menuList = val.menu.list;
        this.defaultOpeneds = val.menu.openeds;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getCollapse(val) {
      this.collapse = val;
    }
  }
};