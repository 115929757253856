import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  const _component_el_sub_menu = _resolveComponent("el-sub-menu");
  const _component_el_menu = _resolveComponent("el-menu");
  const _component_el_aside = _resolveComponent("el-aside");
  const _component_main_container = _resolveComponent("main-container");
  const _component_el_main = _resolveComponent("el-main");
  const _component_el_container = _resolveComponent("el-container");
  const _component_no_privilege = _resolveComponent("no-privilege");
  const _component_base_menu = _resolveComponent("base-menu");
  return _openBlock(), _createBlock(_component_base_menu, {
    type: "manage",
    "menu-list": $data.menuList,
    onGetCollapse: $options.getCollapse
  }, {
    default: _withCtx(() => [_withDirectives(_createVNode(_component_el_container, null, {
      default: _withCtx(() => [_createVNode(_component_el_aside, {
        width: "auto"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_menu, {
          router: "",
          "text-color": "#fff",
          class: "el-menu-vertical",
          "default-active": _ctx.$route.path,
          collapse: $data.collapse,
          "default-openeds": $data.defaultOpeneds,
          "background-color": "#545c64"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.menuList, item => {
            return _openBlock(), _createElementBlock("div", {
              key: item.id
            }, [item.children.length === 0 ? (_openBlock(), _createBlock(_component_el_menu_item, {
              key: 0,
              index: item.link
            }, {
              title: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(item.name), 1)]),
              default: _withCtx(() => [_createVNode(_component_el_icon, null, {
                default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                  class: "menu-icon"
                }))]),
                _: 2
              }, 1024)]),
              _: 2
            }, 1032, ["index"])) : (_openBlock(), _createBlock(_component_el_sub_menu, {
              key: 1,
              index: item.link,
              style: {
                "color": "#000"
              }
            }, {
              title: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                class: "menu-icon"
              })), _createElementVNode("span", null, _toDisplayString(item.name), 1)]),
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, sub => {
                return _openBlock(), _createElementBlock("div", {
                  key: sub.id
                }, [sub.children.length === 0 ? (_openBlock(), _createBlock(_component_el_menu_item, {
                  key: 0,
                  index: sub.link
                }, {
                  title: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(sub.name), 1)]),
                  default: _withCtx(() => [_createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(sub.icon), {
                      class: "menu-icon"
                    }))]),
                    _: 2
                  }, 1024)]),
                  _: 2
                }, 1032, ["index"])) : (_openBlock(), _createBlock(_component_el_sub_menu, {
                  key: 1,
                  index: sub.link,
                  style: {
                    "color": "#000"
                  }
                }, {
                  title: _withCtx(() => [_createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(sub.icon), {
                      class: "menu-icon"
                    }))]),
                    _: 2
                  }, 1024), _createElementVNode("span", null, _toDisplayString(sub.name), 1)]),
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sub.children, subItem => {
                    return _openBlock(), _createBlock(_component_el_menu_item, {
                      key: subItem.id,
                      index: subItem.link
                    }, {
                      title: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(subItem.name), 1)]),
                      default: _withCtx(() => [_createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(subItem.icon), {
                          class: "menu-icon"
                        }))]),
                        _: 2
                      }, 1024)]),
                      _: 2
                    }, 1032, ["index"]);
                  }), 128))]),
                  _: 2
                }, 1032, ["index"]))]);
              }), 128))]),
              _: 2
            }, 1032, ["index"]))]);
          }), 128))]),
          _: 1
        }, 8, ["default-active", "collapse", "default-openeds"])]),
        _: 1
      }), _createVNode(_component_el_main, null, {
        default: _withCtx(() => [_createVNode(_component_main_container)]),
        _: 1
      })]),
      _: 1
    }, 512), [[_vShow, _ctx.$store.state.menuVisible]]), _withDirectives(_createVNode(_component_el_container, null, {
      default: _withCtx(() => [_createVNode(_component_no_privilege)]),
      _: 1
    }, 512), [[_vShow, !_ctx.$store.state.menuVisible]])]),
    _: 1
  }, 8, ["menu-list", "onGetCollapse"]);
}